import clsx from 'clsx/lite';
import { AutocompleteSpecialitiesItemOption, DoctorOption } from './types';
import { UseComboboxReturnValue } from 'downshift';
import { HomeIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { CHANNEL_VIDEO } from '@/costants/defaultValues';
import { ELTooltip } from '@davincihealthcare/elty-design-system-react';

type AutocompleteSpecialityItemProps = {
  combobox: UseComboboxReturnValue<AutocompleteSpecialitiesItemOption>;
  item: AutocompleteSpecialitiesItemOption;
  index: number;
};

const AutocompleteSpecialityItem = ({ combobox, item, index }: AutocompleteSpecialityItemProps) => {
  // https://davincisalute.slack.com/archives/C019GBX80EM/p1727962768671039?thread_ts=1726471584.151719&cid=C019GBX80EM
  const isDisabled = item?.doctor?.nextAvailability === undefined && item?.doctor?.macrospeciality !== 'PSYCHOLOGY';

  const renderChildren = (doctor: DoctorOption) => {
    const doctorPriceText = `${doctor.hasDynamicPrice ? 'da ' : ''}${doctor.price}`;

    return (
      <div className="flex flex-shrink-0 items-center gap-2 text-xs">
        {doctor.domiciliary ? (
          <HomeIcon className="text-primary-900 h-4 w-4 stroke-2" />
        ) : doctor.channels?.includes(CHANNEL_VIDEO) ? (
          <VideoCameraIcon className="text-primary-900 h-4 w-4 stroke-2" />
        ) : null}

        {doctor.price && doctorPriceText}
      </div>
    );
  };

  const section = () => {
    return item.doctor ? (
      isDisabled ? (
        <ELTooltip text={'Temporaneamente non disponibile'}>
          <div className="text-md my-4 flex w-full items-center justify-between gap-2 rounded-md p-1 text-neutral-inactive hover:cursor-not-allowed">
            {item.service} {renderChildren(item.doctor)}
          </div>
        </ELTooltip>
      ) : (
        <div
          {...combobox.getItemProps({ item })}
          className={clsx(
            'text-md my-4 flex items-center justify-between gap-2 rounded-md p-1 hover:cursor-pointer hover:bg-primary-light-hover active:bg-primary-light-pressed',
            combobox.highlightedIndex === index && 'bg-primary-200 ',
          )}
        >
          {item.service}
          {renderChildren(item.doctor)}
        </div>
      )
    ) : (
      <div className={`text-md cursor-default p-1`}>{item.service}</div>
    );
  };

  const doctor = () => {
    return isDisabled ? (
      <ELTooltip text={'Temporaneamente non disponibile'} className="w-auto">
        {
          <div
            className={`flex items-center justify-between gap-2  p-1 text-sm font-normal text-neutral-inactive hover:cursor-not-allowed`}
          >
            {`${item.doctor?.name} ${item.doctor?.surname}`}
            {item.doctor && renderChildren(item.doctor)}
          </div>
        }
      </ELTooltip>
    ) : (
      <div className="rounded-md p-1 hover:cursor-pointer hover:bg-primary-light-hover active:bg-primary-light-pressed">
        <div
          className={clsx(
            'flex justify-between gap-2 hover:cursor-pointer hover:bg-primary-light-hover active:bg-primary-light-pressed',
            combobox.highlightedIndex === index && 'bg-primary-200',
          )}
          {...combobox.getItemProps({ item: item })}
        >
          <div className={`text-sm font-normal text-neutral-darker `}>{`${item.doctor?.name} ${item.doctor?.surname}`}</div>
          {item.doctor && renderChildren(item.doctor)}
        </div>{' '}
      </div>
    );
  };

  return <li className={clsx('m-1 flex-col items-center font-semibold text-primary')}>{item.isSectionTitle ? section() : doctor()}</li>;
};

export default AutocompleteSpecialityItem;
