export const measureInputValueWidth = (input: HTMLInputElement): number => {
  const span = document.createElement('span');
  span.style.whiteSpace = 'pre';
  const style = getComputedStyle(input);
  span.style.fontFamily = style.fontFamily;
  span.style.fontSize = style.fontSize;
  span.style.fontWeight = style.fontWeight;
  span.style.fontStyle = style.fontStyle;
  span.style.letterSpacing = style.letterSpacing;
  span.style.textTransform = style.textTransform;
  span.style.paddingLeft = style.paddingLeft;
  span.style.margin = style.margin;
  span.style.border = style.border;
  span.style.boxSizing = style.boxSizing;
  span.style.position = 'absolute';
  span.style.left = '-9999px';
  span.style.top = '-9999px';
  span.innerText = input.value;

  document.body.appendChild(span);
  const width = span.getBoundingClientRect().width;
  document.body.removeChild(span);
  return width;
};

export const convertRemToPixels = (rem: number, input: HTMLInputElement) => {
  return rem * parseFloat(getComputedStyle(input).fontSize);
};
