import { ELButton, ELIconButton } from '@davincihealthcare/elty-design-system-react';
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';

type Props = {
  backButtonEnabled?: boolean;
  buttonsSize?: 'small' | 'medium' | 'large';
  className?: string;
  description: string;
  titleVariant?: 'title' | 'backButton';
  onClose?(): void;
  onTurnBack?(): void;
};

export const WidgetNavigator = ({
  buttonsSize = 'medium',
  className,
  description,
  backButtonEnabled,
  titleVariant = 'title',
  onClose,
  onTurnBack,
}: Props) => {
  return (
    <div className={`flex w-full items-center justify-between ${className}`}>
      {backButtonEnabled && onTurnBack && (
        <ELIconButton
          aria-label="Torna indietro"
          variant="filled"
          color="primary"
          className={`outline-none${titleVariant === 'backButton' ? ' mr-2' : ''}`}
          size={buttonsSize}
          onClick={onTurnBack}
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </ELIconButton>
      )}
      {titleVariant === 'backButton' ? (
        <div className="flex-start flex flex-1">
          <ELButton color="primary" variant="text" size="small" label={description} aria-label={description} onClick={onTurnBack} />
        </div>
      ) : (
        <h2 className={'flex-1 text-center text-xl font-semibold text-primary'}>{description}</h2>
      )}

      {onClose && (
        <ELIconButton aria-label="Close" variant="filled" color="primary" className="outline-none" size={buttonsSize} onClick={onClose}>
          <XMarkIcon className="h-5 w-5" />
        </ELIconButton>
      )}
    </div>
  );
};
