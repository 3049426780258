import { useCombobox } from 'downshift';
import React, { HTMLAttributes, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { AutocompleteSpecialitiesItemOption } from './types';
import clsx from 'clsx/lite';
import AutocompleteSpecialityItem from './autocompleteSpecialityItem';
import { HomeIcon, MagnifyingGlassIcon, VideoCameraIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { filterAutocompleteItemsBySpeciality, makeAutocompleteItems } from '@/utils/reservation';
import { AggregatorSpeciality, SpecialityChannel } from '@/types-aggregatore';
import { convertRemToPixels, measureInputValueWidth } from '@/utils/autocompleteUtils';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { getStringFromQueryParam } from '@/utils/string';

type SpecialitiesAutocompleteProps = PropsWithChildren<{
  id: string;
  specialities: AggregatorSpeciality[];
  clinicDomiciliary?: boolean;
  clinicName?: string;
  setSpecialitySelected: (selected: null | AutocompleteSpecialitiesItemOption) => void;
  initialSpecialityLabel?: string;
  listClassName?: HTMLAttributes<HTMLUListElement>['className'];
}>;

const SpecialitiesAutocomplete = ({
  id,
  specialities,
  clinicDomiciliary,
  clinicName,
  setSpecialitySelected,
  initialSpecialityLabel,
  listClassName,
}: SpecialitiesAutocompleteProps) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  const [isPriceReady, setPriceReady] = useState(false);

  const getInitialSpeciality = (defaultSpecialityLabel?: string) => {
    if (!defaultSpecialityLabel) return null;

    const specialityId = getStringFromQueryParam(searchParams?.get('specialityId') ?? undefined);
    const doctorId = getStringFromQueryParam(searchParams?.get('doctorId') ?? undefined);

    if (specialityId && doctorId) {
      return items.find(i => i.doctor?.serviceId?.toLowerCase() === specialityId.toLowerCase() && i.doctor.id === doctorId) ?? null;
    }
    if (specialityId) {
      return items.find(i => i.doctor?.serviceId?.toLowerCase() === specialityId.toLowerCase()) ?? null;
    }

    const services = items.filter(i => i.service && i.service?.toLowerCase() === defaultSpecialityLabel?.toLowerCase());
    if (services.length === 1) {
      return services[0];
    }

    const filteredService = services?.filter(i => !i.isSectionTitle);
    const lowestPriceService = filteredService.reduce(
      (min: AutocompleteSpecialitiesItemOption | null, current: AutocompleteSpecialitiesItemOption | null) =>
        parseFloat(current?.doctor?.price?.split('€')?.[0] ?? '0') < parseFloat(min?.doctor?.price?.split('€')?.[0] ?? '0') ? current : min,
      null,
    );
    return lowestPriceService || filteredService?.[0];
  };

  const [items, setAutocompleteItems] = useState<AutocompleteSpecialitiesItemOption[]>(() => {
    if (initialSpecialityLabel) {
      const filteredItems = filterAutocompleteItemsBySpeciality(
        makeAutocompleteItems(specialities, clinicDomiciliary),
        initialSpecialityLabel,
      );
      if (filteredItems.length) return filteredItems;
    }
    return makeAutocompleteItems(specialities);
  });

  const getInitialValue = () => {
    const initialValue = getInitialSpeciality(initialSpecialityLabel);
    if (initialValue) {
      return initialValue;
    }
    return null;
  };

  const removeSelection = () => {
    combobox.selectItem(null);
    setSpecialitySelected(null);
  };

  const combobox = useCombobox({
    items,
    id: id,
    itemToString: item => {
      if (item) return `${item?.service}`;
      else return '';
    },
    onInputValueChange: ({ inputValue, selectedItem }) => {
      if (inputValue) {
        setAutocompleteItems(filterAutocompleteItemsBySpeciality(makeAutocompleteItems(specialities, clinicDomiciliary), inputValue));

        // Reset input selection if we changed text and the new text is not in the list
        if (
          selectedItem &&
          !items?.find(
            item =>
              selectedItem.doctor?.id === item.doctor?.id &&
              item.service?.toLowerCase().replace(/ /g, '').includes(inputValue?.toLowerCase().replace(/ /g, '')),
          )
        ) {
          combobox.selectItem(null);
        }
        combobox.setInputValue(inputValue);

        // Change url in structure page based on the selected option
        const specialityId = getStringFromQueryParam(searchParams?.get('specialityId') ?? undefined);
        const speciality = getStringFromQueryParam(searchParams?.get('speciality') ?? undefined);
        if (speciality && specialityId) {
          router.push(`${pathname}?speciality=${selectedItem?.service}&specialityId=${selectedItem?.doctor?.serviceId}`);
        }
      } else {
        setAutocompleteItems(makeAutocompleteItems(specialities, clinicDomiciliary));
        combobox.selectItem(null);
      }
    },
    initialSelectedItem: getInitialValue(),
    inputId: `${id}-input`,
    labelId: `${id}-label`,
    menuId: `${id}-items-list`,
  });
  // Sync combobox.selectedItem with state and set price element
  useEffect(() => {
    setSpecialitySelected(combobox.selectedItem ?? null);

    // Note it would be better to use ref here, but it seems it has some issues with combobox
    const inputRef = document.getElementById(`${id}-input`) as HTMLInputElement;

    if (priceRef.current) {
      // 2rem = cross icon (1 rem) + padding right (0.5) and left(0.5)
      const paddingIconRight = convertRemToPixels(2, inputRef);
      const textLength = measureInputValueWidth(inputRef);

      inputRef.style.paddingRight = `calc(${
        // If we have a price add the width of the price + paddingIconRight
        combobox.selectedItem?.doctor?.price ? priceRef.current.offsetWidth : 0
      }px + ${paddingIconRight}px)`;

      if (combobox.selectedItem?.doctor?.price) {
        // Set position to left of the input text or to the right of the input if the text is too long
        priceRef.current.style.left =
          inputRef.offsetWidth !== 0
            ? Math.min(textLength, inputRef.offsetWidth - paddingIconRight - priceRef.current.offsetWidth) + 8 + 'px'
            : textLength + 8 + 'px';
      }
      setPriceReady(true);
    } else {
      inputRef.style.paddingRight = '32px';
    }
  }, [combobox.selectedItem, setSpecialitySelected, id]);

  const priceRef = useRef<HTMLInputElement>(null);

  const leftIcon = useMemo(() => {
    if (combobox.selectedItem) {
      if (clinicDomiciliary) {
        return <HomeIcon className="h-5 w-5" />;
      } else if (combobox.selectedItem.doctor?.channels?.includes(SpecialityChannel.Online)) {
        return <VideoCameraIcon className="h-5 w-5" />;
      }
      return null;
    }

    return <MagnifyingGlassIcon className="text-primary-900 h-5 w-5" />;
  }, [clinicDomiciliary, combobox.selectedItem]);

  const fallbackComponent = () => {
    return (
      <div className="flex flex-col items-center justify-center gap-6 p-16">
        <h3 className="text-center text-base font-semibold text-gray-700">
          La prestazione &quot;{combobox.inputValue}&quot; non è disponibile presso la struttura {clinicName}.
        </h3>
        <span className="text-center text-sm font-normal text-gray-700">
          Controlla che il nome della prestazione sia stato inserito correttamente oppure cerca una nuova prestazione!
        </span>
      </div>
    );
  };

  return (
    <div className="relative mb-8 h-full w-full" data-cy="autocomplete" aria-label={`${id}-label`}>
      <div className="relative flex items-center ">
        {leftIcon && <span className="absolute pl-2">{leftIcon}</span>}

        <input
          className={clsx(
            `bg-primary-100
            border-primary-500
            text-primary-900
            placeholder-primary-900
            autofill:shadow-primary-100
            focus:ring-primary
            w-full
            overflow-hidden
            truncate
            rounded-md
            border
            py-2
            autofill:shadow-[inset_0_0_0px_1000px]
            focus:border-primary
            focus:outline-none
            focus:ring-1`,
            leftIcon ? 'pl-8' : 'pl-2',
          )}
          name="specialityInput"
          placeholder="Cerca una prestazione"
          {...combobox.getInputProps()}
        />

        {combobox.selectedItem?.doctor?.price && (
          <div
            ref={priceRef}
            className={clsx(
              'absolute flex h-full items-center gap-2 text-base font-semibold text-primary-active',
              combobox.selectedItem && isPriceReady && combobox.selectedItem?.doctor?.price ? 'visible' : 'invisible -z-10',
            )}
          >
            {combobox.selectedItem?.doctor?.price}
          </div>
        )}

        <div className="relative">
          <label className="flex items-center" htmlFor="specialityInput">
            {combobox.selectedItem && (
              <div className="absolute right-2 flex items-center gap-1">
                <div className="flex items-center gap-1 ">
                  <button onClick={() => removeSelection()}>
                    <XMarkIcon className="text-primary-900 h-4 w-4 stroke-2" />
                  </button>
                </div>
              </div>
            )}
          </label>
        </div>
      </div>

      <ul
        className={clsx(
          `
          w-full
          ${combobox.selectedItem ? 'hidden' : ''}
          scrollbar-show
          absolute
          z-50
          mb-24
          mt-2
          overflow-y-scroll
          rounded-md
          bg-white
          text-slate-500
          `,
          combobox.isOpen && !!items.length && 'z-[1000] py-3',
          listClassName,
        )}
        {...combobox.getMenuProps()}
      >
        {combobox.inputValue.length > 1 && items.length < 1
          ? fallbackComponent()
          : items.map((service, index) => {
              return (
                <AutocompleteSpecialityItem
                  key={`${service.doctor?.id}-${service.doctor?.serviceId}-${index}`}
                  item={service}
                  combobox={combobox}
                  index={index}
                />
              );
            })}
      </ul>
    </div>
  );
};

export default SpecialitiesAutocomplete;
